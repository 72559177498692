import axiosResiInstance from "../http/axiosResi";

export const OWNER_TYPE = "owner";
export const MANAGER_TYPE = "manager";

export const APPROVED_STATUS = "approved";
export const DECLINED_STATUS = "declined";
export const WAITING_FOR_APPROVAL = "waiting_for_approval";

export const STATUSES = {
    [APPROVED_STATUS]: "Approved",
    [DECLINED_STATUS]: "Declined",
    [WAITING_FOR_APPROVAL]: "Waiting for Approval",
};

export const geocodeAddress = (address) => {
    return axiosResiInstance.get("/geocode", {
        params: {
            address,
        },
    });
};

export const claimPropertyUser = (address) => {
    return axiosResiInstance.post("/claim-property-user", {
        address,
    });
};

export const claimPropertyManager = (address) => {
    return axiosResiInstance.post("/claim-property-manager", {
        address,
    });
};

export const changePropertyManagerStatus = (
    userPropertyManagerRequestId,
    status
) => {
    return axiosResiInstance.put(
        `/claim-property-manager/${userPropertyManagerRequestId}/status`,
        {
            status,
        }
    );
};

export const getUserProperties = (type, status, limit, offset) => {
    return axiosResiInstance.get("/my-properties", {
        params: {
            type,
            status,
            limit,
            offset,
        },
    });
};

export const getUserProperty = (userPropertyId) => {
    return axiosResiInstance.get(`/my-properties/${userPropertyId}`);
};

export const createMyListing = (
    userPropertyId,
    category,
    type,
    price,
    title,
    description
) => {
    return axiosResiInstance.post("/my-listings", {
        user_property: userPropertyId,
        category,
        type,
        price,
        title,
        description,
    });
};

export const activateMyListing = (
    listingId,
    userPropertyId,
    category,
    type,
    price,
    title,
    description
) => {
    return axiosResiInstance.put(`/my-listings/${listingId}`, {
        type,
        price,
        title,
        description,
    });
};

export const deleteMyListing = (listingId) => {
    return axiosResiInstance.delete(`/my-listings/${listingId}`);
};

export const deleteManager = (userPropertyId, managerId) => {
    return axiosResiInstance.delete(
        `/user-property-manager/${userPropertyId}/${managerId}`
    );
};

export const deleteAgent = (userPropertyId, agentId) => {
    return axiosResiInstance.delete(
        `/my-listings-delete-agent/${userPropertyId}/${agentId}`
    );
};

export const updateUserProperty = (userPropertyId, data) => {
    return axiosResiInstance.post(`/my-properties/${userPropertyId}`, data);
};

export const isLeadAssigned = (lead, profile) => {
    if (!lead || !lead.lead_assignments) {
        return false;
    }

    let isAssigned = false;

    lead.lead_assignments.map((leadAssign) => {
        const isAgent =
            leadAssign.agent &&
            leadAssign.agent.user &&
            leadAssign.agent.user.id === profile.id;
        const isUser = leadAssign.user && leadAssign.user.id === profile.id;

        if (isAgent || isUser) {
            isAssigned = true;
        }
    });

    return isAssigned;
};

export const getAssignedLeads = (userProperty, profile) => {
    const leads = userProperty.leads;

    if (!leads || leads.length === 0) {
        return [];
    }

    let assignedLeads = [];

    leads.map((lead) => {
        if (profile.id === userProperty.owner_user.id || isLeadAssigned(lead, profile)) {
            assignedLeads.push(lead);
        }
    });

    return assignedLeads;
};

export const getNumberOfLeads = (userProperty, profile) => {
    const assignedLeads = getAssignedLeads(userProperty, profile);

    return assignedLeads.length;
};

export const isAuthUserOwner = (userProperty, profile) => {
    return profile.id === userProperty.owner_user.id;
};

export const getAllowedPropertyDataForEditing = (data) => {
    // photos
    data["photos"] = data.photos ? data.photos : [];

    // lists
    data.property_type = data.property_type.name;
    data.property_sub_type = data.property_sub_type.name;

    // delete
    delete data.id;
    delete data.primary_photo;
    delete data.url;
    delete data.full_address;
    delete data.address;
    delete data.zip;
    delete data.city;
    delete data.state;
    delete data.county;
    delete data.latitude;
    delete data.longitude;
    delete data.created_date;
    delete data.last_updated_date;

    return data;
};

export const isCheckedBoolInput = (itemValue, inputValue) => {
    const value = getItemValue(inputValue);

    return (
        (itemValue === "1" && value === "1") ||
        (itemValue === "0" && value === "0") ||
        (itemValue === "" && value === "")
    );
};

export const getItemValue = (inputValue) => {
    return inputValue === true || inputValue === 1 || inputValue === "1"
        ? "1"
        : inputValue === ""
        ? ""
        : "0";
};

export const PROPERTY_DATA_STRING_INPUTS = [
    {
        label: "Unit Number",
        name: "unit_number",
        maxLength: 100,
        inputMode: "text",
    },

    // NUMBERS
    {
        label: "Living Area",
        name: "living_area",
        maxLength: 10,
        inputMode: "decimal",
    },
    {
        label: "Lot Size (in sqft)",
        name: "lot_size",
        maxLength: 10,
        inputMode: "decimal",
    },
    { label: "Bedrooms", name: "bedrooms", maxLength: 4, inputMode: "decimal" },
    {
        label: "Bathrooms",
        name: "bathrooms",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "Full Bathrooms",
        name: "full_bathrooms",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "Half Bathrooms",
        name: "half_bathrooms",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "One Quarter Bathrooms",
        name: "one_quarter_bathrooms",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "Three Quarter Bathrooms",
        name: "three_quarter_bathrooms",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "Room Count",
        name: "room_count",
        maxLength: 15,
        inputMode: "decimal",
    },
    {
        label: "Building Unit Count",
        name: "building_unit_count",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "Condo Floor Number",
        name: "condo_floor_num",
        maxLength: 15,
        inputMode: "decimal",
    },
    {
        label: "Number of Floors",
        name: "num_floors",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "Number of Parking Spaces",
        name: "num_parking_spaces",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "Year Updated",
        name: "year_updated",
        maxLength: 4,
        inputMode: "decimal",
    },
    {
        label: "Year Built",
        name: "year_built",
        maxLength: 4,
        inputMode: "decimal",
    },

    // STRINGS
    {
        label: "Legal Description",
        name: "legal_description",
        maxLength: 255,
        inputMode: "text",
    },
    {
        label: "Location Directions",
        name: "location_directions",
        maxLength: 255,
        inputMode: "text",
    },
];

export const PROPERTY_DATA_BOOL_INPUTS = [
    { label: "Has a Basement?", name: "has_basement" },
    { label: "Has a Dock?", name: "has_dock" },
    { label: "Has an Elevator?", name: "has_elevator" },
    { label: "Has a Fireplace?", name: "has_fireplace" },
    { label: "Has a Greenhouse?", name: "has_greenhouse" },
    { label: "Has a Hot Tub Spa?", name: "has_hot_tub_spa" },
    { label: "Has a Pool?", name: "has_pool" },
    { label: "Has a Sauna?", name: "has_sauna" },
    { label: "Has a Sports Court?", name: "has_sports_court" },
    { label: "Is it New Construction?", name: "is_new_construction" },
    { label: "Is it a Waterfront?", name: "is_waterfront" },
];

export const BOOL_VALUES = [
    ["1", "Yes"],
    ["0", "No"],
    ["", "Not applicable"],
];
