import React from "react";
import PropTypes from "prop-types";
import { getAvatarPhotoUrl } from "../../service/photo";

const AvatarImgUrl = "/static/img/avatar.png";

const Avatar = (props) => {
    const { photo, size } = props;
    const photoSrc = photo ? getAvatarPhotoUrl(photo, size) : AvatarImgUrl;

    return <img src={photoSrc} alt="Profile" />;
};

Avatar.propTypes = {
    photo: PropTypes.string,
    size: PropTypes.string.isRequired,
};

export default Avatar;
