import React from "react";
import PropTypes from "prop-types";
import css from "../../css/listing/badge.module.css";

const Badge = ({ color, label }) => {
    return <div className={css[`badge-${color}`]}>{label}</div>;
};

Badge.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string,
};

export default Badge;
