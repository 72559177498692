import React from "react";
import axiosResiInstance from "../http/axiosResi";

import PointIcon from "../static/icons/point.svg";
import ProfessionalHexagonIcon from "../static/icons/professional-hexagon.svg";
import PercentIcon from "../static/icons/percent.svg";
import EnhancedIcon from "../static/icons/enhanced.svg";
import PropertyBadgeIcon from "../static/icons/badge.svg";
import clone from "../helper/clone";

export const AGENT_ACTIVE_STATUS = "active";
export const AGENT_HIDDEN_STATUS = "hidden";
export const AGENT_DEACTIVATED_STATUS = "deactivated";

export const PRODUCT_ENHANCED = "enhanced";
export const PRODUCT_LISTING_PRO = "listing_pro";
export const PRODUCT_FEATURED_ZIP = "featured_zip";
export const PRODUCT_MARKET_SHARE = "market_share";
export const PRODUCT_PROPERTY_BADGE = "property_badge";

export const ADDON_PRODUCTS = [
    PRODUCT_FEATURED_ZIP,
    PRODUCT_MARKET_SHARE,
    PRODUCT_LISTING_PRO,
];

export const PRODUCTS = [
    { label: "All", value: null },
    { label: "Real Estate Pro", value: PRODUCT_ENHANCED },
    { label: "Listing Pro", value: PRODUCT_LISTING_PRO },
    { label: "Featured Agent", value: PRODUCT_FEATURED_ZIP },
    { label: "Market Share", value: PRODUCT_MARKET_SHARE },
    { label: "Property Badge", value: PRODUCT_PROPERTY_BADGE },
];

export const PRODUCT_LABELS = {
    [PRODUCT_ENHANCED]: "Real Estate Pro",
    [PRODUCT_LISTING_PRO]: "Listing Pro",
    [PRODUCT_FEATURED_ZIP]: "Featured Agent",
    [PRODUCT_MARKET_SHARE]: "Market Share",
    [PRODUCT_PROPERTY_BADGE]: "Property Badge",
};

export const PRODUCT_ICONS = {
    [PRODUCT_ENHANCED]: <EnhancedIcon />,
    [PRODUCT_LISTING_PRO]: <ProfessionalHexagonIcon />,
    [PRODUCT_FEATURED_ZIP]: <PointIcon />,
    [PRODUCT_MARKET_SHARE]: <PercentIcon />,
    [PRODUCT_PROPERTY_BADGE]: <PropertyBadgeIcon />,
};

export const TRANSACTION_CLASS_NAMES = {
    charged: "badgeSuccess",
    error: "badgeDanger",
    new: "badgeInfo",
    refunded: "badgeWarning",
    voided: "badgePrimary",
};

export const GENDERS = [
    ["male", "Male"],
    ["female", "Female"],
];

export const AGENT_SPECIALITIES = [
    ["FLAT_FEE", "Flat Fee"],
    ["FORECLOSURE", "Foreclosure"],
    ["FAST_TURN_AROUND", "Fast Turn Around"],
    ["RELOCATION", "Relocation"],
    ["NEW_CONSTRUCTION", "New Construction"],
    ["VETERAN_SPECIALIST", "Veteran Specialist"],
    ["HOME_STAGING", "Home Staging"],
    ["INVESTMENT", "Investment"],
    ["FIRST_TIME_BUYER", "First Time Buyer"],
    ["SHORT_SALES", "Short Sales"],
];

export const AGENT_LANGUAGES = [
    ["ENGLISH", "English"],
    ["SPANISH", "Spanish"],
    ["CHINESE", "Chinese"],
    ["TAGALOG", "Tagalog (including Filipino)"],
    ["VIETNAMESE", "Vietnamese"],
    ["ARABIC", "Arabic"],
    ["FRENCH", "French"],
    ["KOREAN", "Korean"],
    ["RUSSIAN", "Russian"],
    ["GERMAN", "German"],
    ["HAITIAN_CREOLE", "Haitian Creole"],
    ["HINDI", "Hindi"],
    ["PORTUGUESE", "Portuguese"],
    ["ITALIAN", "Italian"],
    ["POLISH", "Polish"],
    ["URDU", "Urdu"],
    ["JAPANESE", "Japanese"],
    ["PERSIAN", "Persian (including Farsi and Dari)"],
    ["GUJARATI", "Gujarati"],
    ["TELUGU", "Telugu"],
    ["BENGALI", "Bengali"],
    ["TAI_KADAI", "Tai-Kadai (including Thai and Lao)"],
    ["GREEK", "Greek"],
    ["PUNJABI", "Punjabi"],
    ["TAMIL", "Tamil"],
    ["ARMENIAN", "Armenian"],
    ["SERBO_CROATIAN", "Serbo-Croatian"],
    ["HEBREW", "Hebrew"],
    ["HMONG", "Hmong"],
    ["BANTU", "Bantu (including Swahili)"],
    ["KHMER", "Khmer"],
    ["NAVAJO", "Navajo"],
];

export const LICENSES = [
    ["", "Not Available"],
    ["Apprentice Inspector", "Apprentice Inspector"],
    ["Auctioneer", "Auctioneer"],
    ["Certified General Appraiser", "Certified General Appraiser"],
    ["Certified Residential Appraiser", "Certified Residential Appraiser"],
    ["Leasing Agent", "Leasing Agent"],
    ["Licensed Appraiser", "Licensed Appraiser"],
    ["Licensed Inspector", "Licensed Inspector"],
    ["Mortgage Broker", "Mortgage Broker"],
    ["Other", "Other"],
    ["Property Management", "Property Management"],
    ["Real Estate Broker", "Real Estate Broker"],
    ["Registered Appraiser", "Registered Appraiser"],
    ["Registered Inspector", "Registered Inspector"],
    ["Rental Property Management", "Rental Property Management"],
    ["Salesperson", "Salesperson"],
    ["Unknown", "Unknown"],
];

export const fetchMyLocations = (product, type = "zip") => {
    return axiosResiInstance.get(`/my-locations`, {
        params: {
            type,
            product,
        },
    });
};

export const fetchMyAgentLocations = () => {
    return axiosResiInstance.get("/my-agent-locations");
};

export const editRealEstateProfile = (values) => {
    let data = clone(values);

    Object.keys(data).forEach((key) => {
        if (data[key] === "") {
            data[key] = null;
        }
    });

    let servingAreaIds = [];

    data.serving_areas.forEach((area) => {
        servingAreaIds.push(area.value);
    });

    data.serving_areas = servingAreaIds.join(",");
    data.specialities = data.specialities ? data.specialities.join(",") : null;
    data.languages = data.languages ? data.languages.join(",") : null;

    if (data.price_range && data.price_range.max && data.price_range.max > 0) {
        data.price_range = data.price_range.min + "," + data.price_range.max;
    } else {
        data.price_range = null;
    }

    return axiosResiInstance.put("/my-real-estate-profile", data);
};

export const postEnhancedProduct = () => {
    return axiosResiInstance.post(`/my-enhanced`);
};

export const getEnhancedProduct = () => {
    return axiosResiInstance.get(`/my-enhanced`);
};

export const postFeaturedProduct = (data) => {
    return axiosResiInstance.post(`/my-featured-zip`, data);
};

export const getFeaturedProduct = (limit, offset = 0) => {
    return axiosResiInstance.get(`/my-featured-zip`, {
        params: {
            limit,
            offset,
        },
    });
};

export const getMarketShareProduct = (limit, offset = 0) => {
    return axiosResiInstance.get(`/my-market-share`, {
        params: {
            limit,
            offset,
        },
    });
};

export const getListingProProduct = () => {
    return axiosResiInstance.get(`/my-listing-pro`);
};

export const postProductRequest = (data) => {
    return axiosResiInstance.post(`/my-product-request`, data);
};

export const getProductList = (limit, offset = 0) => {
    return axiosResiInstance.get(`/my-products`);
};

export const fetchAgent = (agentId) => {
    return axiosResiInstance.get(`/agents/${agentId}`);
};
